const params = document.getElementById("subscriptionScript").dataset;
const id = params.id
const serviceUrl = params.serviceurl
const type = params.type
const value = id
const site = params.site
const codes = params.codes
const enableSubscriptions = params.enablesubscriptions
const title = params.title

$(document).ready(function () {
  $('#error' + id).hide()
  $('#success' + id).hide()

  $(document).on('submit', '#subscribe' + id, function (event) {
    event.preventDefault();
  });
})

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.\d{1,3}\.[0-9]{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function showError() {
  $('#error' + id).show()
}

function showSuccess() {
  $('#subscribe' + id).hide()
  $('#success' + id).show()
}

let codeString = ''
if (codes) {
  codes.substring(1, codes.length - 1).split(', ').forEach(function (code) {
    codeString += ('&code=' + code)
  })
}

function signup() {
  const email = $('#email' + id).val()
  const valid = validateEmail(email)
  if (valid) {
    // Call subscription
    let url = serviceUrl + '?email=' + email + '&type=' + type + '&value=' + value + '&site=' + site + `&title=${title}`

    if (type === 'code') {
      url += codeString
    }

    if(enableSubscriptions && enableSubscriptions === 'true') {
      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: function () {
          showSuccess()
        }, error: function (jqXHR, textStatus, errorThrown) {
          showError()
        }
      })
    } else {
      console.log('subscriptions are disabled')
    }
  } else {
    showError()
  }
}

const hasParentWithClass = ($("#signup").parents().hasClass('l-container'))
if (hasParentWithClass) {
  $("#signup").removeClass('l-container')
}
